import { Link } from 'gatsby'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image'

import solutionBgDotsOne from '~/assets/images/case-study/iexpedition/bg-solution-dots-1.svg'
import solutionBgDotsTwo from '~/assets/images/case-study/iexpedition/bg-solution-dots-2.svg'
import videoReviewLogo from '~/assets/images/case-study/iexpedition/video-review-logo.svg'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyClientVideoReview from '~/views/CaseStudies/components/CaseStudyClientVideoReview'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'

import * as containerStyles from './IExpedition.module.scss'
import useIExpeditionStaticQuery from './useIExpeditionStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Ben Alexander',
    position: 'Director, iExpedition',
  },
  be: {
    name: 'Maksym',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewAfricarTitle = 'Multi-Vendor Vehicle Marketplace'
const previewDVTitle = 'Online Collaboration Marketplace'

const CaseStudiesIExpedition = () => {
  const query = useIExpeditionStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const clientVideoReview = getImage(
    fileToImageLikeData(query.clientVideoReview),
  )
  const ship = getImage(fileToImageLikeData(query.ship))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyHead
          title="Online travel marketplace for iExpedition"
          subtitle="Learn how Codica helped iExpedition double the expected sales revenue by building a powerful global travel marketplace platform."
          maxWidthSubtitle="400px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Online travel marketplace for iExpedition"
          imageTitle="Online travel marketplace case study"
          domain="Travel"
          location="Buderim, Queensland, Australia"
          timeline="2016 – present"
          services={
            <>
              <Link key="designKey" to="/services/ui-ux-design/">
                UX/UI design services
              </Link>
              , Front-end and Back-end development
            </>
          }
          team="1 Project Manager, 1 UX/UI Designer, 3 Software Developers, 1 QA Engineer"
          technologies="Ruby on Rails, PostgreSQL, Redis/Sidekiq, JQuery, Heroku"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyClientVideoReview
          color="videoReviewIx"
          link="https://www.youtube.com/embed/njVC42-gThw"
          clientImage={clientVideoReview}
          imageTitle="Customer review about online travel marketplace project"
          logo={videoReviewLogo}
          name="Ben Alexander, Director iExpedition"
          review={[
            {
              item: '“We started working with Codica in 2016. The team helped us to build a customizable online booking system for a specialized online cruise agency.',
            },
            {
              item: 'From the beginning, the whole process of development has been an absolute pleasure. The team operates on the highest professional level and communicates effectively.',
            },
            {
              item: 'Our relationship with Codica has been unique - I have personally worked alongside Codica more than with the travel software company we had in Australia.”',
            },
          ]}
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudyBackgroundContainer}>
        <CaseStudyBackground
          backgroundColor={containerStyles.caseStudyBackground__bgColor}
          lineColor={containerStyles.caseStudyBackground__lineColor}
          bgTextLeft="iExpedition is an Australia-based online expedition and adventure travel company providing polar cruises to the edge of the world. The company ensures fully equipped vessels and highly skilled tour guides. This way, the safety of the cruises is ensured."
          bgTextRight="The main idea of the project was to build a complex online tour and travel management system. The platform should provide travelers with a large selection of destinations and tour options and a convenient booking system."
          sectionBusiness={[
            {
              label:
                'Build a fast-loading and visually-appealing online travel marketplace to drive more customers and increase sales.',
            },
            {
              label:
                'Create an efficient content management system for the global travel marketplace to automate the booking process and handle all the cruise records easily.',
            },
            {
              label:
                'Develop a comprehensive cruise booking system with advanced search to help customers find the most suitable cruise.',
            },
          ]}
          sectionValueDelivered={[
            {
              label:
                'Built a responsive, SEO-friendly and easy-to-navigate travel marketplace that boosted iExpedition web presence and sales. A convenient CMS, integrated guidebook and a simple booking system gained the trust of customers worldwide. As a result, the company was able to double the expected revenue.',
            },
            {
              label:
                'Developed an effective and easy-to-manage content management system for the travel portal. As a result of travel software development, our client could systematize and automate the processes of creating and organizing the content.',
            },
            {
              label:
                'Implemented advanced search functionality. It enabled travelers to book a cruise based on their specific requirements such as duration, destination, or price. Also, it brings automation of the booking processes development and cost-efficiency of the online travel marketplace.',
            },
          ]}
        />
        <div className={containerStyles.caseStudyBackgroundContainer__ship}>
          <GatsbyImage
            image={ship as IGatsbyImageData}
            alt="iExpedition Ship | Codica"
            title="iExpedition Ship"
            style={{ display: 'block' }}
          />
        </div>
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“iExpedition brings the wildest dreams of polar cruises into reality. We were excited to take on such an ambitious and vivid project. And I think that we got it right by delivering a fast, efficient, and vibrant travel marketplace.',
            },
            {
              text: 'Developing travel software for iExpedition was a great experience due to many aspects. Firstly, we embraced the tech challenges that we needed to overcome as a travel software development agency. Thus, we were interested in building a Ruby on Rails booking system that makes cruise booking easy and customizable.',
            },
            {
              text: 'Furthermore, we saw the information architecture of the website from another angle. In my opinion, that is what custom software development for the travel industry is all about – to create a platform that is both appealing and convenient to users.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Max.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you want to build a custom online booking platform?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Secure online booking system"
          description={
            <>
              In the process of travel platform development, we aimed at
              building a convenient online booking system. Our task was to
              provide safe and fast payments and hassle-free booking management.
              In the long run we succeeded in
              <Link
                key="keyCaseSolutionCustomSoft"
                to="/services/custom-software-development/"
                style={{ color: '#232323' }}
                className="mx5"
              >
                custom software development services
              </Link>
              for the travel platform that brought security and efficiency to
              all the processes.
            </>
          }
          alt="User-friendly cruise booking system for iExpedition"
          imageTitle="Convenient cruise booking system for iExpedition"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="UX and UI design"
          description="Codica’s expert team developed a structure for the website that both meets the needs of users and follows the brand identity of iExpedition. During travel platform development, the feel and look of the website play a big part. All of the pages were thoroughly designed to support the company’s overall goal."
          alt="UI and UX design for global travel marketplace"
          imageTitle="UI/UX design for iExpedition travel marketplace"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Extensive ship listing"
          description="The travel marketplace supplies tourists with a wide range of vessels. Users can filter results by destination, ship size, name, and vessel standard. Also, travelers can explore each vessel and check the important vessel details, including key facts or technical specs. Armed with this valuable data, travelers will be able to make a well-considered decision."
          alt="Extensive ship listing for iExpedition"
          imageTitle="Diverse ship listing for global travel marketplace iExpedition"
          bgRings
          bgRingImage={solutionBgDotsOne}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Easy access to educational content"
          description="The online travel marketplace aims to provide travelers with interesting facts about territories they are going to conquer. For this purpose, we made sure that our customer can easily publish valuable educational information. Also, we have an extensive information tree of the website to give tourists easy access to easy-to-use and informative content."
          alt="Comprehensive cruise booking system for iExpedition"
          imageTitle="Extensive cruise booking system for iExpedition"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Powerful CMS"
          description="The booking system development platform offers extensive information on tours. Given that, our customer needed to manage all content easily. To solve this task, we built a content management system for the tour and travel software. It provides easy and dynamic content updates. Multiple features of the CMS help handle all the records and automate the processes of content creation and management."
          alt="Effective content management system for online travel marketplace"
          imageTitle="Powerful content management system for global travel marketplace"
          bgRings
          bgRingImage={solutionBgDotsTwo}
          bgRingClass={containerStyles.caseStudySolution__ringTwo}
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Advanced cruise search"
          description="Implemented an advanced tour search functionality with quick access to all available cruises. The integrated tour listing contains the key information for an adventurer, from destination to price and duration. When it comes to custom software development for the travel industry, rich tour customization helps users set extra requirements for their cruises or update the default ones."
          alt="Advanced search functionality for the travel marketplace platform"
          imageTitle="Advanced search for the travel marketplace platform"
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Codica team is highly competent in travel software development. The travel marketplace they delivered has exceeded our goals over the past 7 months. In fact, we have processed twice as many sales as we were aiming to achieve during the first 12 months!',
            },
            {
              text: 'Furthermore, the system is very responsive to search algorithms, with many of our views appearing on Google page 1 already.',
            },
            {
              text: 'I highly recommend Codica team as a proven travel software development company. And I can honestly say that if it wasn’t for Codica, I do not know if I could have managed to bring the travel marketplace alive for the budget that we had.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-iExpedition.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseStudyTech}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'ActiveAdmin', icon: 'activeadmin' },
            { label: 'Redis', icon: 'redis' },
            { label: 'JQuery', icon: 'jquery' },
            { label: 'RSpec', icon: 'rspec' },
          ]}
          integrations={[
            { label: 'Heroku', icon: 'heroku' },
            { label: 'SendGrid', icon: 'sendgrid' },
            { label: 'Intercom', icon: 'intercom' },
            { label: 'Sentry.io', icon: 'sentry' },
            { label: 'Skylight', icon: 'skylight' },
            { label: 'Paypal', icon: 'paypal' },
            { label: 'MailChimp', icon: 'mailchimp' },
          ]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewAfricarTitle}
              color="africarColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/africar.png"
                  alt={`Case study: ${previewAfricarTitle} | Codica`}
                  title={`Case study: ${previewAfricarTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="multi-vendor-vehicle-marketplace"
            />
            <CaseStudyPreview
              title={previewDVTitle}
              color="dvColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/dv.png"
                  alt={`Case study: ${previewDVTitle} | Codica`}
                  title={`Case study: ${previewDVTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                />
              }
              url="collaboration-marketplace-and-smart-contract-system"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesIExpedition
