// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_kw";
export var caseStudyBackgroundContainer = "bk_ky";
export var caseStudyBackgroundContainer__ship = "bk_kz";
export var caseStudyBackground__bgColor = "bk_ks";
export var caseStudyBackground__lineColor = "bk_kt";
export var caseStudyHead__imageWrapper = "bk_kp";
export var caseStudyProjectsSection = "bk_kx";
export var caseStudyQuote__bgLight = "bk_kD";
export var caseStudyQuote__bgRing = "bk_kr";
export var caseStudySolution__ring = "bk_kB";
export var caseStudySolution__ringTwo = "bk_kC";
export var caseStudyTech = "bk_kF";
export var caseStudy__bgDark = "bk_km";
export var caseStudy__bgLight = "bk_kl";